import React from "react"
import { PageTitle, Seo } from "../components/common"
import { Layout } from "../components/layout"
import { Contact, Banner } from "../components/sections"

const ContactPage = () => {
  return (
    <>
      <Seo title={"Contact"} />

      <Layout>
        <PageTitle title="Contact Me" />
        <Contact displayTitle={false} />
        <Banner />
      </Layout>
    </>
  )
}

export default ContactPage
